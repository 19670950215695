<template>
  <div>
    <MallheaderVue :titlename="'收货地址'"></MallheaderVue>
    <div class="addresscontent">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" @load="onLoad">
          <div class="addresscard">
            <div
              v-for="(item, index) in addressList"
              :key="index"
              class="ordercell"
            >
              <div class="addressitem" @click="selectAddress(item)">
                <img
                  class="addicon"
                  :src="require('@/assets/images/mall/locationicon.png')"
                />
                <div class="addinfo">
                  <div class="userinfo">
                    <span class="name">{{ item.consignee }}</span
                    ><span class="tel">{{ item.mobile }}</span>
                  </div>
                  <div class="addtxt van-multi-ellipsis--l2">
                    {{ item.province_str }} {{ item.city_str
                    }}{{ item.district_str }}{{ item.area }}
                  </div>
                </div>
                <van-icon @click.stop="editAddress(item)" size="20" name="edit" />
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="addcard" @click="clicknewaddress">
        <img
          class="addicon"
          :src="require('@/assets/images/mall/addicon.png')"
        />
        <div class="addtxt">添加地址</div>
      </div>
    </div>
    <newaddress ref="newaddress" @updateAddress="updateAddress"></newaddress>
  </div>
</template>

<script>
import { addressList } from "@/request/shopapi";
import Newaddress from "./actionsheet/newaddress.vue";
import MallheaderVue from "../../components/mallheader.vue";
export default {
  components: {
    MallheaderVue,
    Newaddress,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      addressList: [],
        address:'',
        userInfo:{},
    };
  },
  created() {},
  activated() {
      this.address = sessionStorage.getItem("address");
      this.userInfo = sessionStorage.getItem("user")
          ? JSON.parse(sessionStorage.getItem("user"))
          : {};
    this.onRefresh();
  },
  methods: {
    selectAddress(addressitem) {
      console.log(addressitem);
      this.$eventBus.$emit("uploadAddress", addressitem);
      this.$router.go(-1);
    },
    requestaddressList() {
      addressList({ address: this.address }).then((res) => {
        this.addressList = res.data;
        if (this.refreshing) {
          this.refreshing = false;
        }
        this.loading = false;
        this.finished = true;
      });
    },
    clicknewaddress() {
      this.$refs.newaddress.preview();
    },
    editAddress(item) {
      this.$refs.newaddress.preview(item);
    },
    updateAddress() {
      this.onRefresh();
    },
    onLoad() {
      this.requestaddressList();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.addresscontent {
  background: #ebeef2;
  padding: 0 24px;
  height: 100vh;
  .addresscard {
    width: 702px;
    min-height: 168px;
    border-radius: 8px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    .addressitem {
      padding: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .addicon {
        width: 64px;
        height: 64px;
      }
      .addinfo {
        width: 472px;
        .userinfo {
          font-size: 24px;
          .name {
            color: rgba(41, 33, 29, 1);
            font-size: 28px;
            font-weight: 700;
            font-family: "PingFang SC";
            margin-right: 8px;
          }
          .tel {
            color: rgba(105, 98, 95, 1);
            font-size: 24px;
            font-weight: 500;
            font-family: "DIN";
          }
        }
        .addtxt {
          margin-top: 8px;
          color: rgba(105, 98, 95, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
      }
      .arrow {
        width: 32px;
        height: 32px;
        opacity: 1;
      }
    }
  }
  .addcard {
    margin-top: 24px;
    width: 702px;
    height: 110px;
    border-radius: 8px;
    opacity: 1;
    border: 2px dashed rgba(189, 41, 223, 1);
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    padding: 24px;
    .addicon {
      width: 64px;
      height: 64px;
      margin-right: 24px;
    }
    .addtxt {
      color: rgba(41, 33, 29, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: left;
    }
  }
}
</style>
