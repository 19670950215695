<template>
  <div>
    <van-action-sheet v-model="showAction" :title="'添加地址'" class="modalclass">
      <div class="newbox">
        <div class="inputbox">
          <van-field label-align="right" label-width="60" v-model="consignee" label="姓名" placeholder="请输入姓名" />
        </div>
        <div class="inputbox marginT16">
          <van-field
            label-align="right"
            label-width="60"
            v-model="mobile"
            label="手机号码"
            placeholder="请输入手机号码"
          />
        </div>
        <div class="inputbox marginT16" @click="showArea">
          <van-field
            readonly
            label-align="right"
            label-width="60"
            v-model="aresvalue"
            label="收货地址"
            placeholder="省份/市/区"
          />
        </div>
        <div class="textareabox marginT16">
          <van-field
            label-align="right"
            label-width="60"
            v-model="area"
            label="详细地址"
            type="textarea"
            placeholder="请输入详细地址"
          />
        </div>
        <div class="setdefault marginT16">
          <div class="txt">默认地址</div>
          <van-switch v-model="is_default" active-color="#FF7220" inactive-color="#dcdee0" size="24px" />
        </div>
        <div class="btncontent">
          <div class="btn" @click="showpwd = true">保存</div>
        </div>
      </div>
    </van-action-sheet>
    <van-popup v-model="showarealist" position="top">
      <van-area title="选择地址" :area-list="areaList" @confirm="clicksure" @cancel="clickcancel"></van-area>
    </van-popup>
    <van-dialog v-model="showpwd" title="安全密码" @cancel="pwdShow" @confirm="clickStore" show-cancel-button>
      <van-field
        type="password"
        v-model="password"
        name="安全密码"
        label="安全密码"
        placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import { provinceList, cityList, districtList } from "@/request/shopapi";
import { areaList } from "@vant/area-data";
import { saveUserAddress } from "@/request/shopapi";
import { Toast } from "vant";
export default {
  data() {
    return {
      provinceArr: [],
      cityArr: [],
      districtArr: [],
      areaList,
      addressid: "",
      showAction: false,
      consignee: "",
      mobile: "",
      aresvalue: "",
      area: "",
      provinceID: "",
      cityID: "",
      districtID: "",
      showarealist: false,
      is_default: true,
      selectitem: [],
      address: "",
      userInfo: {},
      showpwd: false,
      password: "",
    };
  },
  methods: {
    pwdShow() {
      this.showpwd = false;
    },
    requestprovinceList() {
      provinceList().then((res) => {
        this.provinceArr = res.data;
      });
    },
    requestcityList() {
      const params = {
        province_id: this.provinceID,
      };
      cityList(params).then((res) => {
        this.cityArr = res.data;
        this.filterCity();
      });
    },
    requestdistrictList() {
      const params = {
        city_id: this.cityID,
      };
      districtList(params).then((res) => {
        this.districtArr = res.data;
        this.filterDistrict();
      });
    },
    requestSaveAddress() {
      if (this.consignee.length === 0) {
        Toast("请输入姓名");
        return;
      }
      if (this.mobile.length === 0) {
        Toast("请输入手机号码");
        return;
      }
      if (this.area.length === 0) {
        Toast("请输入详细地址");
        return;
      }
      const params = {
        address: this.address,
        pay_code: this.password,
        consignee: this.consignee,
        mobile: this.mobile,
        province: this.provinceID,
        city: this.cityID,
        district: this.districtID,
        area: this.area,
        is_default: this.is_default,
        id: this.addressid,
      };
      saveUserAddress(params).then((res) => {
        Toast(res.msg);
        let success = res.success;
        if (success) {
          this.showAction = false;
          this.$emit("updateAddress");
        }
      });
    },
    clickStore() {
      this.requestSaveAddress();
    },
    preview(item) {
      this.address = sessionStorage.getItem("address");
      this.userInfo = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};
      this.requestprovinceList();
      if (item) {
        console.log(item);
        this.addressid = item.id;
        this.consignee = item.consignee;
        this.mobile = item.mobile;
        this.province = item.province;
        this.city = item.city;
        this.district = item.district;
        this.area = item.area;
        this.is_default = item.is_default === 1 ? true : false;
        this.aresvalue = item.province_str + item.city_str + item.district_str;
        this.provinceID = item.province;
        this.cityID = item.city;
        this.districtID = item.district;
      } else {
        this.addressid = "";
        this.consignee = "";
        this.mobile = "";
        this.province = "";
        this.city = "";
        this.district = "";
        this.area = "";
        this.is_default = false;
        this.aresvalue = "";
        this.provinceID = "";
        this.cityID = "";
        this.districtID = "";
      }
      this.showAction = true;
    },
    showArea() {
      this.showarealist = true;
    },
    clicksure(value) {
      this.showarealist = false;
      this.aresvalue = value[0].name + value[1].name + value[2].name;
      this.selectitem = value;
      this.filterProvince();
    },

    filterProvince() {
      let sprovince = this.selectitem[0].name;
      this.provinceID = this.provinceArr.find((item) => {
        if (item.name === sprovince) {
          return item;
        }
      }).id;
      console.log(this.provinceID);
      this.requestcityList();
    },
    filterCity() {
      let scity = this.selectitem[1].name;
      this.cityID = this.cityArr.find((item) => {
        if (item.name === "市辖区") {
          return item;
        }
        if (item.name === scity) {
          return item;
        }
      }).id;
      this.requestdistrictList();
    },
    filterDistrict() {
      let sdistrict = this.selectitem[2].name;
      this.districtID = this.districtArr.find((item) => {
        if (item.name === sdistrict) {
          return item;
        }
      }).id;
    },
    clickcancel() {
      this.showarealist = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.marginT16 {
  margin-top: 16px;
}
.modalclass {
  border-radius: 12px 12px 0px 0px;
  background: white;
  .newbox {
    padding: 16px 16px 100px;
    .inputbox {
      width: 686px;
      height: 84px;
      border-radius: 16px;
      opacity: 1;
      border: 2px solid rgba(255, 223, 204, 1);
      background: rgba(250, 247, 245, 1);
      ::v-deep .van-cell {
        background-color: transparent;
        line-height: 20px;
      }
    }
    .textareabox {
      width: 686px;
      min-height: 156px;
      border-radius: 16px;
      opacity: 1;
      border: 2px solid rgba(255, 223, 204, 1);
      background: rgba(250, 247, 245, 1);
      ::v-deep .van-cell {
        background-color: transparent;
      }
    }
    .setdefault {
      display: flex;
      justify-content: space-between;
      padding: 0 32px;
      align-items: center;
      .txt {
        color: rgba(0, 0, 0, 0.9);
        font-size: 24px;
        font-weight: 400;
        text-align: right;
        line-height: 36px;
      }
    }
    .btncontent {
      display: flex;
      justify-content: center;
      margin-top: 74px;
      .btn {
        width: 478px;
        height: 72px;
        border-radius: 74px;
        opacity: 1;
        background: rgba(255, 114, 32, 1);
        color: rgba(238, 238, 238, 1);
        font-size: 28px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
</style>
